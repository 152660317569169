import { TypeAnimation } from "react-type-animation";

export const TypeAnimate = () => {
  return (
    <TypeAnimation
      sequence={[
        '"Create with the heart; build with the mind"',
        2000,
        '"Deadlines and things make you creative"',
        2000,
        '"The creative adult is the child who survived"',
        2000,
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: "2em", display: "inline-block" }}
      repeat={Infinity}
    />
  );
};
export default TypeAnimate;
