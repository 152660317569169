import React from "react";
import { Box, Image } from "@chakra-ui/react";
import floating from "./assets/floating5.png";
import { useSpring, animated } from "react-spring";
const AnimatedBox = animated(Box);

const FirstPageFloat = () => {
  const figureAnimation = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(30px, 50px)" },
  });
  return (
    <div>
      <Box
        position="absolute"
        right={"10rem"}
        transform="translateY(-50%)"
        zIndex={1}
        style={{ width: "800px" }}
      >
        <AnimatedBox style={figureAnimation}>
          <Image src={floating} alt="Figures" />
        </AnimatedBox>
      </Box>
    </div>
  );
};

export default FirstPageFloat;
