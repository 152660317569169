import React from "react";
import { UserData, UserContextType } from "../types";

const defaultState: UserContextType = {
  userData: {
    accessToken: "",
    refreshToken: "",
    userId: "",
  },
  updateUser: () => {},
};

export const UserContext = React.createContext<UserContextType>(defaultState);

export default UserContext;
