import React from "react";
import { Highlight } from "@chakra-ui/react";
import {
  Box,
  IconButton,
  VStack,
  Text,
  Center,
  Skeleton,
  SkeletonText,
  Heading,
  Container,
  Stack,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { useLanguage } from "./LanguageContext";
import { Plan, LessonStages } from "../types";
import { FiArrowRight } from "react-icons/fi";
interface MiddleProps {
  plan?: Plan;
  isLoading: boolean;
}

interface SectionProps {
  title: string;
  fontSize: string;
  content: string[];
}

const Section: React.FC<SectionProps> = ({ title, fontSize, content }) => {
  return (
    <Box marginLeft={"2rem"} marginRight={"2rem"} marginTop={"1rem"}>
      <Heading fontSize={fontSize}>{title}</Heading>
      <Box
        borderRadius={6}
        bgColor={"white"}
        height={"100%"}
        borderWidth={"1px"}
      >
        {content.map((item, index) => (
          <Box key={index} p={2}>
            {item}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

interface LessonStagesProps {
  lessonStages: LessonStages[];
}

const LessonStagesComponent: React.FC<LessonStagesProps> = ({
  lessonStages,
}) => {
  return (
    <Box>
      {lessonStages.map((stage, index) => (
        <Box key={index} p={2} borderBottomWidth="1px" borderColor="gray.200">
          <Text fontWeight="bold">{stage.lesson_stage}</Text>
          <Text>Teacher Activity: {stage.teacher_activity}</Text>
          <Text>Student Activity: {stage.student_activity}</Text>
          <Text>Working Time: {stage.working_time}</Text>
          <Text>Methods & Procedures: {stage.methods_procedures}</Text>
          <Text>Assessment: {stage.assessment}</Text>
        </Box>
      ))}
    </Box>
  );
};

export const Middle: React.FC<MiddleProps> = ({ plan, isLoading }) => {
  const { language } = useLanguage();

  if (isLoading) {
    return (
      <Box
        width={`calc(100% - 330px)`}
        left="60px"
        top="70px"
        height={"100%"}
        position={"fixed"}
      >
        <Box
          width={`calc(100% - 4rem)`}
          borderRadius={6}
          bgColor={"white"}
          height={"100%"}
          margin={"1rem"}
          borderWidth={"1px"}
        >
          <Skeleton height="30px" margin={"2rem"} />
          <SkeletonText margin={"2rem"} noOfLines={4} spacing="4" />
          <Skeleton height="30px" margin={"2rem"} />
          <SkeletonText margin={"2rem"} noOfLines={2} spacing="4" />
          <Skeleton height="30px" margin={"2rem"} />
          <SkeletonText margin={"2rem"} noOfLines={2} spacing="4" />
          <Skeleton height="30px" margin={"2rem"} />
          <SkeletonText margin={"2rem"} noOfLines={1} spacing="4" />
          <Skeleton height="30px" margin={"2rem"} />
          <SkeletonText margin={"2rem"} noOfLines={1} spacing="4" />
          <Skeleton height="30px" margin={"2rem"} />
          <Skeleton height="30px" margin={"2rem"} />
        </Box>
      </Box>
    );
  } else if (!plan) {
    return (
      <Box
        width={`calc(100% - 330px)`}
        left="60px"
        top="70px"
        height={"100%"}
        position={"fixed"}
      >
        <Box
          width={`calc(100% - 4rem)`}
          borderRadius={6}
          bgColor={"white"}
          height={"100%"}
          margin={"1rem"}
          borderWidth={"1px"}
        >
          <Flex height="70vh" align="center" p={"10rem"}>
            <VStack spacing={4} align="start">
              <Heading fontSize={"50px"} fontWeight={"medium"} noOfLines={3}>
                {language === "en"
                  ? "With the help of AI and a lot of teachers, we developed a tool that helps you engage and teach people"
                  : "Cu ajutorul inteligenței artificiale și a numeroși profesori, am dezvoltat o unealtă care vă ajută să vă implicați și să învățați oamenii."}
              </Heading>
              <HStack>
                <Box
                  bg="#1053F3" // Set background color to blue
                  pt={2}
                  pb={2}
                  paddingLeft={"3rem"}
                  paddingRight={"3rem"} // Set padding
                  borderRadius="full" // Fully rounded corners
                  display="flex" // Use flex display for child elements
                  alignItems="center" // Align children vertically in the center
                >
                  <Text
                    fontSize={"37px"}
                    fontWeight={"medium"}
                    color="white"
                    mr={2}
                  >
                    {language === "en"
                      ? "Start your creative process here"
                      : "Începe-ți procesul creativ chiar aici."}
                  </Text>
                  <FiArrowRight color="white" fontSize={"36px"} />
                </Box>
              </HStack>
            </VStack>
          </Flex>
        </Box>
      </Box>
    );
  }
  let fontSz = "16px";
  console.log(isLoading);
  return (
    <Box
      width={`calc(100% - 330px)`}
      left="60px"
      top="70px"
      height={"100%"}
      position={"fixed"}
    >
      <Box
        width={`calc(100% - 4rem)`}
        borderRadius={6}
        bgColor={"white"}
        height={"100%"}
        margin={"1rem"}
        borderWidth={"1px"}
        overflow="auto"
        pb="150px"
      >
        <Section
          title="Objectives"
          fontSize={fontSz}
          content={plan.objectives}
        />
        <Section
          title="General Competencies"
          fontSize={fontSz}
          content={plan.general_competencies}
        />
        <Section
          title="Specific Competencies"
          fontSize={fontSz}
          content={plan.specific_competencies}
        />
        <Section
          title="Didactic Strategies"
          fontSize={fontSz}
          content={plan.didactic_strategies}
        />
        <Section
          title="Bibliography"
          fontSize={fontSz}
          content={plan.bibliography}
        />
        <Box marginLeft={"2rem"} marginRight={"2rem"} marginTop={"1rem"}>
          <Heading fontSize={fontSz}>Lesson Stages</Heading>
          <Box
            borderRadius={6}
            bgColor={"white"}
            height={"100%"}
            borderWidth={"1px"}
          >
            <LessonStagesComponent lessonStages={plan.lesson_stages} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Middle;
