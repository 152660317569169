import React, { useState, useEffect } from "react";
import { Footer, Header } from "../components";
import { Text, Box, Center, Heading, Button } from "@chakra-ui/react";
import {
  Sidebar,
  TopBar,
  ConfigurationMenu,
  LoadingSandbox,
} from "../components";
export const Plan: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {loading ? (
        <LoadingSandbox />
      ) : (
        <div>
          <Sidebar />
          <TopBar />
          <ConfigurationMenu />
        </div>
      )}
    </>
  );
};

export default Plan;
