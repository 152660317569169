import React, { useState } from "react";
import { Middle } from "../components";
import {
  Box,
  VStack,
  Container,
  HStack,
  Text,
  Textarea,
  Input,
  Badge,
  Flex,
  Slider,
  Button,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { plans } from "../api";
import { Plan } from "../types";
import { FiPenTool } from "react-icons/fi";
import { set } from "firebase/database";
import DragDrop from "./DragNDrop";
import { useLanguage } from "./LanguageContext";
interface FormData {
  topic: string;
  student_grade: number;
  duration: number;
  subject: string;
  language: string;
  difficulty: number;
  // file: File | null;
}

interface FormErrors {
  topic?: string;
  student_grade?: string;
  duration?: string;
  subject?: string;
  language?: string;
}

export const ConfigurationMenu: React.FC = () => {
  const [planData, setPlanData] = useState<Plan | any>();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    topic: "",
    student_grade: 1,
    duration: 1,
    subject: "",
    language: "",
    difficulty: 1,
    // file: null,
  });
  const [validationErrors, setValidationErrors] = useState<FormErrors>({});
  const { language } = useLanguage();
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    let newValue: string | number;

    if (name === "student_grade" || name === "duration") {
      const parsedValue = value ? parseInt(value) : NaN;

      if (!isNaN(parsedValue)) {
        newValue = parsedValue;
      } else {
        newValue = value === "" ? "" : value;
      }
    } else {
      newValue = value;
    }
    setFormData({ ...formData, [name]: newValue });
  };

  const validateForm = (): boolean => {
    let errors: FormErrors = {};
    let isValid = true;

    if (!formData.topic.trim()) {
      errors.topic = "Topic is required";
      isValid = false;
    }
    if (!formData.subject.trim()) {
      errors.subject = "Subject is required";
      isValid = false;
    }
    if (!formData.language.trim()) {
      errors.language = "Language is required";
      isValid = false;
    }
    if (
      typeof formData.student_grade !== "number" ||
      isNaN(formData.student_grade) ||
      formData.student_grade <= 0
    ) {
      errors.student_grade = "Student grade must be a number greater than 0";
      isValid = false;
    }

    // Check if duration is a number, greater than 0, and not NaN
    if (
      typeof formData.duration !== "number" ||
      isNaN(formData.duration) ||
      formData.duration <= 0
    ) {
      errors.duration = "Duration must be a number greater than 0";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form is valid, submit data:", formData);
      setIsLoading(true);
      try {
        const planData = await plans.getPlan(formData);
        setPlanData(planData);
        console.log("Received plan data:", planData);
      } catch (error) {
        console.error("Error submitting form data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const handleFileDrop = (files: File[]) => {
  //   setFormData({ ...formData, file: files[0]});
  //   console.log(files[0]);
  // };

  return (
    <>
      <Box
        width="300px"
        p="4"
        justifyContent={"flex-end"}
        right={"0px"}
        bgColor={"white"}
        position="fixed"
        height="100vh"
        borderWidth="1px"
      >
        <form onSubmit={handleSubmit}>
          <VStack spacing="4" align="stretch" mt="2rem">
            <Container>
              <HStack>
                <Text>
                  {" "}
                  {language === "en"
                    ? "Create your plan:"
                    : "Crează-ți planul:"}
                </Text>{" "}
                <FiPenTool />
              </HStack>
            </Container>
            <Container>
              <Text fontSize="md">
                {" "}
                {language === "en" ? "Topic" : "Tema:"}
              </Text>
              <Textarea
                variant="filled"
                name="topic"
                fontSize="md"
                placeholder={
                  language === "en" ? "Input your topic" : "Scrie tema aici"
                }
                value={formData.topic}
                onChange={handleInputChange}
              />
              {validationErrors.topic && (
                <Text color="red.500">{validationErrors.topic}</Text>
              )}
            </Container>
            <Container>
              <Text fontSize="md">
                {" "}
                {language === "en" ? "Subject" : "Obiectul de predare:"}
              </Text>
              <Textarea
                variant="filled"
                name="subject"
                fontSize="md"
                placeholder={
                  language === "en"
                    ? "Input your subject"
                    : "Scrie obiectul aici"
                }
                value={formData.subject}
                onChange={handleInputChange}
              />
              {validationErrors.topic && (
                <Text color="red.500">{validationErrors.topic}</Text>
              )}
            </Container>
            <Container>
              <Text fontSize="md">
                {" "}
                {language === "en" ? "Language" : "Limba predată"}
              </Text>
              <Textarea
                variant="filled"
                name="language"
                fontSize="md"
                placeholder={
                  language === "en" ? "Input your language" : "Scrie limba aici"
                }
                value={formData.language}
                onChange={handleInputChange}
              />
              {validationErrors.topic && (
                <Text color="red.500">{validationErrors.topic}</Text>
              )}
            </Container>
            {/* <Container>
            <Text fontSize="md">  {language === 'en' ? 'File:' : 'Fișier'}</Text>
            <DragDrop onFileDrop={handleFileDrop}/>
          </Container> */}
            <Container>
              <Text fontSize="md">
                {" "}
                {language === "en" ? "Student Grade:" : "Clasa studentului:"}
              </Text>
              <Input
                name="student_grade"
                fontSize="md"
                variant="filled"
                placeholder={
                  language === "en"
                    ? "Input student grade"
                    : "Scrie clasa studuentului aici"
                }
                value={formData.student_grade.toString()}
                onChange={handleInputChange}
              />
              {validationErrors.student_grade && (
                <Text color="red.500">{validationErrors.student_grade}</Text>
              )}
            </Container>
            <Container>
              <Text fontSize="md">
                {" "}
                {language === "en" ? "Duration:" : "Durata lecției:"}
              </Text>
              <Input
                name="duration"
                fontSize="md"
                variant="filled"
                placeholder={
                  language === "en"
                    ? "Input duration in minutes"
                    : "Scrie durata lecției în minute"
                }
                value={formData.duration.toString()}
                onChange={handleInputChange}
              />
              {validationErrors.duration && (
                <Text color="red.500">{validationErrors.duration}</Text>
              )}
            </Container>
            <Container>
              <Text fontSize="md">
                {" "}
                {language === "en" ? "Difficulty" : "Dificultatea lecției"}
              </Text>
              <Slider
                colorScheme="messenger"
                fontSize="md"
                aria-label="slider-ex-1"
                defaultValue={2}
                min={1}
                max={3}
                step={1}
                onChange={(val) =>
                  setFormData({ ...formData, difficulty: val })
                }
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Container>
          </VStack>
          <Flex justify="flex-end" mt="4">
            <Button
              borderRadius="full"
              type="submit"
              bgColor={"#1053F3"}
              color={"white"}
            >
              {" "}
              {language === "en" ? "Generate" : "Generează"}
            </Button>
          </Flex>
        </form>
      </Box>
      <Middle plan={planData} isLoading={isLoading} />
    </>
  );
};

export default ConfigurationMenu;
