import React from "react";
import {
  Flex,
  Box,
  Image,
  IconButton,
  HStack,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { IconSettings, IconInfoCircle } from "@tabler/icons-react";
import logo from "../plansight.png";
import { Link } from "react-router-dom";
import SettingsMenu from "./SettingsMenu";

export const TopBar: React.FC = () => {
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1rem"
      paddingLeft={"1rem"}
      bg="white"
      color="black"
      borderBottom="1px"
      borderColor="gray.200"
      width={`calc(100% - 60px)`}
      position="fixed"
      top={0}
      left="60px"
      zIndex={1}
    >
      <Box>
        <HStack spacing={"1rem"}>
          <Link to="/">
            <Image src={logo} alt="Company Logo" height="20px" />
          </Link>
          <Heading fontSize={"lg"} fontWeight={"normal"} color={"#1053F3"}>
            Sandbox
          </Heading>
        </HStack>
      </Box>
      <Flex align="center">
        <IconButton
          aria-label="Info"
          icon={<IconInfoCircle />}
          width={"1.3em"}
          variant="ghost"
          marginRight="2"
        />
        <SettingsMenu />
        {/* <IconButton
          aria-label="Settings"
          icon={<SettingsMenu />}
          width={"1.3em"}
          variant="ghost"
        /> */}
      </Flex>
    </Flex>
  );
};

export default TopBar;
