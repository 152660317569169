import axios from "axios";
import {config} from "../config";

export const plans = {
 
  getPlan: async (body: any) => {
    try {
      const { data } = await axios.post(`${config.apiUrl}/create_plan`, {
        ...body,
      },
      {
        headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":"Access-Control-Allow-Headers, Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization"
        },
      });
      console.log("plan data: ",data);
      return data;
    } catch (error) {
      console.error("Error fetching plans:", error);
      throw error;
    }
  },
};