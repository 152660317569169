import React, { useState, useEffect } from "react";
import {
  Text,
  Box,
  Center,
  Heading,
  Button,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { LoadingPage } from "../components";
import { Header, Footer, Form, useLanguage } from "../components";
import { useDisclosure } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FirstPageAnimation } from "../assets_components";
import FirstPageFloat from "../assets_components/Floating";
export function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { language } = useLanguage();
  const buttonStyle = {
    mt: 10,
    bgColor: "#1053F3",
    colorScheme: "messenger",
    textColor: "white",
    transition: "background-color 0.3s ease #0031A5",
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Header />
          <Center>
            <Box textAlign="center" marginTop={[60, "50", 80]}>
              <HStack>
                <VStack align="start">
                  <Heading
                    ml="4"
                    mr="10"
                    noOfLines={3}
                    width={"80rem"}
                    style={{ textAlign: "left" }}
                    fontSize={"80px"}
                    fontWeight={"medium"}
                    alignItems={"left"}
                    zIndex={4}
                  >
                    {language === "en"
                      ? "Where teachers focus on what matters the most"
                      : "Unde profesorii se focusează pe ceea ce e important"}
                  </Heading>
                  <Text
                    fontSize={"40px"}
                    width={"50rem"}
                    style={{ textAlign: "left" }}
                    noOfLines={3}
                    fontWeight={"medium"}
                    ml="4"
                    mt={3}
                    mr="10"
                    alignItems={"left"}
                    zIndex={4}
                  >
                    {language === "en"
                      ? "A platform for generating content that can save your valuable time"
                      : "O platformă pentru generare de content ce-ți economisește timpul tău prețios"}
                  </Text>

                  <Button
                    ml="4"
                    fontWeight={"normal"}
                    colorScheme="messenger"
                    variant="solid"
                    paddingLeft={"60px"}
                    paddingTop={"30px"}
                    fontSize={"20px"}
                    paddingBottom={"30px"}
                    paddingRight={"60px"}
                    mt={buttonStyle.mt}
                    bgColor={buttonStyle.bgColor}
                    textColor={buttonStyle.textColor}
                    onClick={onOpen}
                  >
                    {language === "en"
                      ? "Sign for the waitlist"
                      : "Înregistrează-te pentru lista de așteptare"}
                    <StarIcon ml={1} />
                  </Button>
                </VStack>
                <FirstPageFloat />
              </HStack>

              <Modal
                isOpen={isOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                isCentered
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    {" "}
                    <Text fontSize={18}>
                      {language === "en"
                        ? "Here you can sign for the waitlist:"
                        : "Aici te poți înregistra:"}
                    </Text>
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Form />
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </Center>
          {/* <Footer/> */}
        </>
      )}
    </>
  );
}
export default App;
