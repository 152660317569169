import axios from "axios";
import { config } from "../config";
import Cookies from "js-cookie";
import { logout } from "../utils/cookies";

interface LoginRequestBody {
  email: string;
  password: string;
}

interface ChangePasswordRequestBody {
  password: string;
}

export interface UserData {
  access_token: string;
  refresh_token: string;
  uuid: string;
}

export const users = {
  login: async (body: LoginRequestBody) => {
    try {
      const { data } = await axios.post(`${config.apiUrl}/user/login`, body, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Access-Control-Allow-Headers, Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
        },
      });
      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  },

  register: async (body: LoginRequestBody) => {
    try {
      const { data } = await axios.post(
        `${config.apiUrl}/user/register`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
          },
        }
      );

      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  },

  changePassword: async (body: ChangePasswordRequestBody) => {
    try {
      const { data } = await axios.post(
        `${config.apiUrl}/user/update-pass`,
        body,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      logout();
      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  },
};
