import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { IconSettings } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../utils/cookies";

const SettingsMenu: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Settings"
        icon={<IconSettings />}
        width={"1.3em"}
        variant="ghost"
      />
      <MenuList textAlign={"right"} fontSize={"1rem"} fontWeight={600}>
        <MenuItem onClick={() => navigate("/change-password")}>
          Change Password
        </MenuItem>
        <MenuItem onClick={logout} color={"red"}>
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default SettingsMenu;
