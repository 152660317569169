import {
  Box,
  FormControl,
  VStack,
  Image,
  FormLabel,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import Logo from "../logo_full.svg";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { users } from "../api";
import { logout } from "../utils/cookies";

const ChangePasswordPage = () => {
  const [password, setPassword] = useState<{
    show: boolean;
    match: boolean;
    valid: boolean;
    value: string;
    confirm: string;
  }>({
    show: false,
    match: true,
    valid: true,
    value: "",
    confirm: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

  const validatePassword = () => {
    if (passwordRegex.test(password.value)) {
      setPassword({ ...password, valid: true });
    } else {
      setPassword({ ...password, valid: false });
    }
  };

  const comparePasswords = () => {
    if (password.value === password.confirm) {
      setPassword({ ...password, match: true });
    } else {
      setPassword({ ...password, match: false });
    }
  };

  const validateForm = () => {
    if (!password.valid) return false;

    if (!password.match) return false;

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      console.log("password: ", password.value);
      handleChangePassword();
    }
  };

  const handleChangePassword = async () => {
    setLoading(true);
    await users
      .changePassword({ password: password.value })
      .then(() => {
        logout();
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        console.error("Error during password change:", error);
        setLoading(false);
        setError("Passoword change failed.");
      });
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        minWidth={"20rem"}
        bgColor="white"
        borderWidth="1px"
        padding={"2rem"}
        borderRadius={"xl"}
        shadow={"sm"}
        marginBottom={"2rem"}
      >
        <VStack spacing={4} align="center">
          {/* Login Form */}
          <Image src={Logo} h={["14px", "20px", "25px", "30px"]} />
          <FormControl isInvalid={!password.valid}>
            <FormLabel>New Password:</FormLabel>
            <InputGroup>
              <Input
                aria-label="New Password"
                type={password.show ? "text" : "password"}
                placeholder="Type your new password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword({ ...password, value: e.target.value })
                }
                onBlur={validatePassword}
              />
              <InputRightElement height={"100%"}>
                <IconButton
                  aria-label="Show password"
                  size={"xs"}
                  icon={password.show ? <ViewIcon /> : <ViewOffIcon />}
                  onClick={() =>
                    setPassword({ ...password, show: !password.show })
                  }
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage fontSize={"0.6rem"} maxWidth={"16rem"}>
              Password must be between 8 and 16 characters long and contain at
              least one lowercase letter, one uppercase letter, one number, and
              one special character.
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!password.match}>
            <FormLabel>Confirm New Password:</FormLabel>
            <Input
              aria-label="Confirm New Password"
              type={password.show ? "text" : "password"}
              placeholder="Confirm new password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword({ ...password, confirm: e.target.value })
              }
              onBlur={comparePasswords}
            />
            <FormErrorMessage fontSize={"0.6rem"} maxWidth={"16rem"}>
              Passwords do not match.
            </FormErrorMessage>
          </FormControl>
          <Button
            isLoading={loading}
            fontWeight={"normal"}
            bgColor="#1053F3"
            colorScheme="messenger"
            width="full"
            mb="1"
            onClick={handleSubmit}
          >
            Change Password
          </Button>
          {error && (
            <Text color={"red"} fontSize={"0.8rem"}>
              {error}
            </Text>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default ChangePasswordPage;
