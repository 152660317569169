import React, { useState, useMemo, FC, ReactNode } from "react";
import UserContext from "./UserContext";
import { UserData } from "../types";

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData>({
    accessToken: "",
    refreshToken: "",
    userId: "",
  });

  const updateUser = (newData: UserData) => {
    setUserData(newData);
  };

  const value = useMemo(() => ({ userData, updateUser }), [userData]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
