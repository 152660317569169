import React from "react";

interface IconProps {
  color: string;
}

export const TablerFileText = ({ color }: IconProps) => (
  <svg width="1.3em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
      <path d="M10 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v3.5M9 9h1m-1 4h2.5M9 17h1"></path>
      <path d="M14 21v-4a2 2 0 1 1 4 0v4m-4-2h4m3-4v6"></path>
    </g>
  </svg>
);

export const TablerTestPipe = ({ color }: IconProps) => (
  <svg width="1.3em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 8.04L7.878 20.164a2.857 2.857 0 1 1-4.041-4.04L15.959 4M7 13h8m4 2l1.5 1.6a2 2 0 1 1-3 0zM15 3l6 6"
    ></path>
  </svg>
);
export const TablerUser = ({ color }: IconProps) => (
  <svg width="1.3em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"
    ></path>
  </svg>
);
export default {
  TablerFileText,
  TablerTestPipe,
  TablerUser,
};
