import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Text,
  Center,
  HStack,
  Image,
  Box,
  Img,
  Button,
  Link as SecondaryLink,
} from "@chakra-ui/react";
import { GoArrowUpRight } from "react-icons/go";
import { ArrowDownIcon } from "@chakra-ui/icons";
import logo from "../logo_full.svg";
import star from "../star.png";
import { Link } from "react-router-dom";
import { useLanguage } from "./LanguageContext";
export const Header: React.FC = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <Center>
      <Box
        position="fixed"
        top="0"
        zIndex="999"
        bg="white"
        shadow={"sm"}
        p={4}
        dropShadow="none"
        textAlign="center"
        alignContent={"center"}
        transition="background-color 0.3s ease-in-out"
        style={{ background: "transparent", boxShadow: "none" }}
        alignItems={["center", "center", "center", "center"]}
        mt={4}
        ml={4}
        mr={4}
        overflow="hidden"
      >
        <Card
          borderRadius="2xl"
          overflow="hidden"
          shadow={"sm"}
          bgColor="rgba(255, 255, 255, 0.7)"
          backdropFilter="blur(10px)"
        >
          <CardBody>
            <HStack
              spacing={[4, 7, 14]}
              justifyContent={"center"}
              ml={5}
              mr={5}
            >
              <Link to="/">
                <Image src={logo} h={["14px", "20px", "20px", "25px"]} />
              </Link>
              <Link to="/plan">
                <HStack spacing={1}>
                  <Button
                    variant="ghost"
                    colorScheme="messenger"
                    fontWeight={"normal"}
                    textColor="black"
                    fontSize={[11, 12, "md", "lg"]}
                  >
                    {language === "en" ? "Lesson Plans" : "Planuri Lecție"}{" "}
                  </Button>{" "}
                </HStack>
              </Link>
              <Link to="/test">
                <Button
                  variant="ghost"
                  colorScheme="messenger"
                  fontWeight={"normal"}
                  textColor="black"
                  fontSize={[11, 12, "md", "lg"]}
                >
                  {language === "en" ? "Tests" : "Teste"}
                </Button>
              </Link>
              <Link to="/pricing">
                <Button
                  variant="ghost"
                  colorScheme="messenger"
                  fontWeight={"normal"}
                  textColor="black"
                  fontSize={[11, 12, "md", "lg"]}
                >
                  {language === "en" ? "Prices" : "Prețuri"}
                </Button>
              </Link>
              <Text
                fontSize={[11, 12, "md", "lg"]}
                fontWeight={"normal"}
                textColor="black"
              >
                <SecondaryLink onClick={toggleLanguage}>
                  {language === "en" ? "EN" : "RO"}
                </SecondaryLink>
              </Text>

              <Link to="/authorize">
                <Button
                  color="white"
                  bgColor="#1053F3"
                  colorScheme="messenger"
                  fontWeight={"normal"}
                  borderRadius="lg"
                  fontSize={[10, 12, "md", "lg"]}
                  padding={"30px"}
                  alignItems="center"
                  display="flex"
                >
                  Log In <GoArrowUpRight style={{ marginLeft: "5px" }} />
                </Button>
              </Link>
            </HStack>
          </CardBody>
        </Card>
      </Box>
    </Center>
  );
};

export default Header;
