import React from "react";
import { Center, Image, VStack, HStack, Heading } from "@chakra-ui/react";
import logo from "../plansight.png";
export const LoadingSandbox: React.FC = () => {
  return (
    <Center height="100vh">
      <VStack>
        <HStack>
          <Image src={logo} h={["17px", "20px", "25px"]} />
          <Heading fontSize={"xl"} fontWeight={"normal"} color={"#1053F3"}>
            Sandbox
          </Heading>
        </HStack>
      </VStack>
    </Center>
  );
};

export default LoadingSandbox;
