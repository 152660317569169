import React from "react";
import Lottie from "lottie-react";
import animationData from "./assets/animation_1.json";

export const FirstPageAnimation = () => {
  return (
    <div>
      <Lottie
        style={{ width: "250px" }}
        animationData={animationData}
        loop={true}
        autoplay={true}
      />
    </div>
  );
};

export default FirstPageAnimation;
