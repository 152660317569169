import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Image,
  Link,
  Text,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react";
import logo from "../logo_full.svg";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../components";
import { IconBrandGoogleFilled } from "@tabler/icons-react";
import { UserData, users } from "../api";
import { cleanCookies, setCookies } from "../utils/cookies";

const commonFormControlProps = {
  width: "full",
};

const AuthPage: React.FC = () => {
  const { language } = useLanguage();

  const [isLoginPage, setIsLoginPage] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleTogglePage = () => {
    setIsLoginPage(!isLoginPage);
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const userData: UserData = await users.login({ email, password });

      cleanCookies();
      setCookies(userData);

      navigate("/plan");
      setLoading(false);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      const userData: UserData = await users.register({ email, password });

      cleanCookies();
      setCookies(userData);

      navigate("/plan");
      setLoading(false);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {isLoginPage ? (
        <Box
          minWidth={"20rem"}
          bgColor="white"
          borderWidth="1px"
          padding={"2rem"}
          borderRadius={"xl"}
          shadow={"sm"}
        >
          <VStack spacing={4} align="center">
            {/* Login Form */}
            <Image src={logo} h={["14px", "20px", "25px", "30px"]} />
            <FormControl {...commonFormControlProps}>
              <FormLabel>Email:</FormLabel>
              <Input
                type="email"
                placeholder="Type your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl {...commonFormControlProps}>
              <FormLabel>
                {language === "en" ? "Password:" : "Parola:"}
              </FormLabel>
              <Input
                type="password"
                placeholder={
                  language === "en" ? "Type your password" : "Scrie parola aici"
                }
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button
              isLoading={loading}
              fontWeight={"normal"}
              bgColor="#1053F3"
              colorScheme="messenger"
              width="full"
              mb="1"
              onClick={handleLogin}
            >
              Login
            </Button>

            <Box>
              {" "}
              Don't have an account yet?{" "}
              <Link
                onClick={handleTogglePage}
                fontWeight={"medium"}
                color={"#1053F3"}
              >
                {" "}
                Register
              </Link>
            </Box>
            <Box width="full" position="relative">
              <Divider />
              <AbsoluteCenter bg="white" px="3">
                or do it with
              </AbsoluteCenter>
            </Box>
            <Button
              variant={"solid"}
              color="gray.800"
              width="full"
              fontWeight={"normal"}
            >
              <IconBrandGoogleFilled />
              <Text>Google (not working :( )</Text>
            </Button>
          </VStack>
        </Box>
      ) : (
        <Box
          bgColor="white"
          borderWidth="1px"
          padding={"2rem"}
          borderRadius={"xl"}
          shadow={"sm"}
        >
          <VStack spacing={4} align="center">
            <Image src={logo} h={["14px", "20px", "25px", "30px"]} />
            <FormControl {...commonFormControlProps}>
              <FormLabel>Email:</FormLabel>
              <Input
                type="email"
                placeholder="Type your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl {...commonFormControlProps}>
              <FormLabel>
                {language === "en" ? "Password:" : "Parola:"}
              </FormLabel>
              <Input
                type="password"
                placeholder={
                  language === "en" ? "Type your password" : "Scrie parola aici"
                }
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button
              isLoading={loading}
              fontWeight={"normal"}
              bgColor="#1053F3"
              colorScheme="messenger"
              width="full"
              mb="1"
              onClick={handleRegister}
            >
              Register
            </Button>

            <Box>
              {" "}
              Already have an account?{" "}
              <Link
                onClick={handleTogglePage}
                fontWeight={"medium"}
                color={"#1053F3"}
              >
                {" "}
                Login{" "}
              </Link>
            </Box>
            <Box width="full" position="relative">
              <Divider />
              <AbsoluteCenter bg="white" px="3">
                or do it with
              </AbsoluteCenter>
            </Box>
            <Button
              variant={"solid"}
              color="gray.800"
              width="full"
              fontWeight={"normal"}
            >
              <IconBrandGoogleFilled />
              <Text>Google (not working :( )</Text>
            </Button>
          </VStack>
        </Box>
      )}
    </Box>
  );
};
export default AuthPage;
