import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Center,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Footer, Header } from "../components";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, push } from "firebase/database";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { UUID } from "crypto";
import { useLanguage } from "./LanguageContext";

type FormData = {
  name: string;
  email: string;
};

const firebaseConfig = {
  apiKey: "AIzaSyBuEHOkV4ssWoysyPsdGyGZaMM4lMxSfck",
  authDomain: "plansight.firebaseapp.com",
  databaseURL:
    "https://plansight-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "plansight",
  storageBucket: "plansight.appspot.com",
  messagingSenderId: "691311864223",
  appId: "1:691311864223:web:5005d070a308c4fbedb5aa",
  measurementId: "G-Y1R45NYN8D",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

function writeUserData(
  db: any,
  name: string,
  email: string,
  user_uuid: string
) {
  set(ref(db, "users/" + user_uuid), {
    username: name,
    email: email,
  });
}

export const Form: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();
  const { language } = useLanguage();
  const toast = useToast();
  const onSubmit = async (data: FormData) => {
    console.log(data);

    try {
      const documentId = writeUserData(
        db,
        data.name,
        data.email,
        crypto.randomUUID()
      );
      console.log(`User added to Firebase with ID: ${documentId}`);
      toast({
        position: "top",
        title: "Registration successful",
        description: "Now you can wait for the beta version",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding user to Firebase:", error);
    }
  };

  return (
    <>
      <Center>
        <Box p={2} w={[350, 400, 500, 700]} bgColor={"white"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name">
                  {language === "en"
                    ? "Name and Surname"
                    : "Numele și Prenumele"}
                </FormLabel>
                <Input
                  borderColor="gray.500"
                  id="name"
                  placeholder={
                    language === "en"
                      ? "Type your name and surname"
                      : "Scrie aici numele și prenumele"
                  }
                  {...register("name", { required: "Name & Surname required" })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email">Email:</FormLabel>
                <Input
                  borderColor="gray.500"
                  type="email"
                  placeholder={
                    language === "en"
                      ? "Input your email"
                      : "Scrie aici emailul"
                  }
                  id="email"
                  {...register("email", { required: "Email is required" })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                colorScheme="messenger"
                size="md"
                width={"full"}
                fontWeight={"normal"}
                bgColor={"#1053F3"}
                color={"white"}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </Center>
    </>
  );
};

export default Form;
