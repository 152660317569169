import React from "react";
import { Spinner, Center, Image, VStack } from "@chakra-ui/react";
import logo from "../logo_full.svg";
export const LoadingPage: React.FC = () => {
  return (
    <Center height="100vh">
      <VStack>
        <Image src={logo} h={["17px", "20px", "25px"]} />
      </VStack>
    </Center>
  );
};

export default LoadingPage;
