import React, { ReactNode, useEffect } from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { checkCookies } from "../utils/cookies";
import { useLocation, useNavigate } from "react-router-dom";

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginState = () => {
    const cookiesExists = checkCookies();

    if (!cookiesExists && location.pathname !== "/authorize") {
      navigate("/authorize");
      window.location.reload();
    }
  }

  useEffect(() => {
    handleLoginState();
  });

  return (
    <Box bgColor="#E7F7FF" minH={"1000px"} zIndex={1}>
      {children}
    </Box>
  );
};

export default Layout;
