import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  VStack,
  Center,
  Text,
  Image,
  Flex,
} from '@chakra-ui/react';

import { FiMenu, FiCodesandbox, FiHexagon, FiUser, FiFileText, FiBookmark } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { BiMessageSquareDetail,  BiSliderAlt, BiHeart } from "react-icons/bi";
import { HiOutlineUser,HiOutlineDocumentText, HiOutlineBeaker } from "react-icons/hi";
import { TablerFileText, TablerTestPipe, TablerUser } from './Icons';
import  logo_black  from '../logo_black.svg'


import { useLanguage } from './LanguageContext';


export const Sidebar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentPath(location.pathname);
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [location.pathname]);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };
  
  const handleMouseLeave = () => {
    setIsExpanded(false);
  };


  function isCurrentPath(path: string) {
    return location.pathname === path;
  }

  const textMinWidth = "140px";
  console.log(location.pathname);
  const isPlanRoute = isCurrentPath("/plan");
  const isTestRoute = isCurrentPath("/test");
  const isUserRoute = isCurrentPath("/user");
  const { language, toggleLanguage } = useLanguage();
  return (
    <Box position="fixed" 
    left={0} 
    top={0} 
    h="100%" 
    bg="white" 
    borderColor={'gray.200'} 
    borderWidth={1} 
    width={isExpanded ? '210px' : '60px'} 
    transition="width 0.3s ease" 
    zIndex={2}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    boxShadow={isExpanded ? "xl" : "none"}
    >
    <VStack spacing={1} minHeight='max-content' align="start" mt={'10px'} p={2}>

      <Flex align="center" mb='5px'>
        <Center>
          <Image ml='4px' src={logo_black} alt="Plansight" maxWidth={'30px'} />
        </Center>
      </Flex>

      <Flex
      align="center"
      bgColor={isPlanRoute ? "rgba(75, 122, 232, 0.3)" : "transparent"}
      transition="background-color 0.3s ease, width 0.3s ease" 
      _hover={{
        bgColor: isPlanRoute ? "rgba(75, 122, 232, 0.3)" : "rgba(0, 0, 0, 0.04)",
        cursor: isPlanRoute ? 'default' : 'pointer',
      }}
      borderRadius="md"
      style={{ maxWidth: isExpanded ? '100%' : 'initial' }}
    >
      <IconButton
        bgColor="transparent"
        aria-label="Home"
        icon={<TablerFileText color={isPlanRoute ? "#1053F3" : "black"} />}
        style={{ pointerEvents: isPlanRoute ? 'none' : 'auto' }}
        variant={isPlanRoute ? 'solid' : 'ghost'}
        _hover={{ bg: isPlanRoute ? "rgba(75, 122, 232, 0.3)" : "transparent" }}
      />
      {isExpanded && (
        <Text
          color={isPlanRoute ? "#1053F3" : "gray.800"}
          ml={2}
          whiteSpace="nowrap"
          minWidth={textMinWidth}
          opacity={isExpanded ? 1 : 0} 
        >
          {language === 'en' ? 'Plan Generation' : 'Generare Planuri'}
        </Text>
      )}
    </Flex>

    <Flex
      align="center"
      bgColor={isTestRoute ? "rgba(75, 122, 232, 0.3)" : "transparent"}
      transition="background-color 0.3s ease, width 0.3s ease" 
      _hover={{
        bgColor: isTestRoute ? "rgba(75, 122, 232, 0.3)" : "rgba(0, 0, 0, 0.04)",
        cursor: isTestRoute ? 'default' : 'pointer',
      }}
      borderRadius="md"
      style={{ maxWidth: isExpanded ? '100%' : 'initial' }}
    >
      <IconButton
        bgColor="transparent"
        aria-label="Home"
        icon={<TablerTestPipe color={isTestRoute ? "#1053F3" : "black"} />}
        style={{ pointerEvents: isTestRoute ? 'none' : 'auto' }}
        variant={isTestRoute ? 'solid' : 'ghost'}
        _hover={{ bg: isTestRoute ? "rgba(75, 122, 232, 0.3)" : "transparent" }}
      />
      {isExpanded && (
        <Text
          color={isTestRoute ? "#1053F3" : "gray.800"}
          ml={2}
          whiteSpace="nowrap"
          minWidth={textMinWidth}
          opacity={isExpanded ? 1 : 0} 
        >
          {language === 'en' ? 'Test Generation' : 'Generare Teste'}
        </Text>
      )}
    </Flex>


    <Flex
      align="center"
      bgColor={isUserRoute ? "rgba(75, 122, 232, 0.3)" : "transparent"}
      transition="background-color 0.3s ease, width 0.3s ease" 
      _hover={{
        bgColor: isUserRoute ? "rgba(75, 122, 232, 0.3)" : "rgba(0, 0, 0, 0.04)",
        cursor: isUserRoute ? 'default' : 'pointer',
      }}
      borderRadius="md"
      style={{ maxWidth: isExpanded ? '100%' : 'initial' }}
    >
      <IconButton
        bgColor="transparent"
        aria-label="Home"
        icon={<TablerUser color={isUserRoute ? "#1053F3" : "black"} />}
        style={{ pointerEvents: isUserRoute ? 'none' : 'auto' }}
        variant={isUserRoute ? 'solid' : 'ghost'}
        _hover={{ bg: isUserRoute ? "rgba(75, 122, 232, 0.3)" : "transparent" }}
      />
      {isExpanded && (
        <Text
          color={isUserRoute ? "#1053F3" : "gray.800"}
          ml={2}
          whiteSpace="nowrap"
          minWidth={textMinWidth}
          opacity={isExpanded ? 1 : 0} 
        >
          {language === 'en' ? 'User Page' : 'Pagina Utilizator'}
        </Text>
      )}
    </Flex>

    </VStack>
    </Box>
  );
};

export default Sidebar;