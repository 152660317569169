import Cookies from 'js-cookie';
import { UserData } from '../api';

export function setCookies(cookies: UserData) {
    const expiryDate = new Date();
    const validTime = 1000 * 60 * 15;
    expiryDate.setMilliseconds(expiryDate.getMilliseconds() + validTime);
    Cookies.set('accessToken', cookies["access_token"], { secure: true, path: '/', sameSite: 'strict', expires: expiryDate });
    Cookies.set('refreshToken', cookies["refresh_token"], { secure: true, path: '/', sameSite: 'strict', expires: expiryDate });
    Cookies.set('uuid', cookies.uuid, { secure: true, path: '/', sameSite: 'strict', expires: expiryDate });
}

export function logout() {
    cleanCookies();
    window.location.reload();
}

export function cleanCookies() {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    Cookies.remove('uuid');
}

export function checkCookies() {
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    const uuid = Cookies.get('uuid');
    return accessToken && refreshToken && uuid;
}