import React from "react";
import { Spinner, Center, Button, VStack, Heading } from "@chakra-ui/react";
import { Highlight } from "@chakra-ui/react";
import { TypeAnimate } from "../components";
import logo from "../plansight.png";
import { FirstPageAnimation } from "../assets_components";
import { useLanguage } from "../components";

export const OopsPage: React.FC = () => {
  const { language } = useLanguage();
  const buttonStyle = {
    mt: 10,
    bgColor: "#1053F3",
    colorScheme: "messenger",
    textColor: "white",
    transition: "background-color 0.3s ease #0031A5", // Add transition for a smoother effect
  };
  return (
    <Center height="100vh">
      <VStack>
        <Heading fontWeight={"normal"} mb={"2rem"}>
          {language === "en" ? (
            <Highlight
              query="development"
              styles={{ px: "1", py: "1", rounded: "md", bg: "#1053F3" }}
            >
              Oops! This page is under development. Check back later!
            </Highlight>
          ) : (
            <Highlight
              query="dezvoltare"
              styles={{ px: "1", py: "1", rounded: "md", bg: "#1053F3" }}
            >
              Oops! Pagina aceasta este in dezvoltare. Reveniți mai târziu!
            </Highlight>
          )}
        </Heading>
        <FirstPageAnimation />
        <Button
          fontWeight={"normal"}
          fontSize={"20px"}
          paddingLeft={"70px"}
          paddingTop={"20px"}
          paddingBottom={"20px"}
          paddingRight={"70px"}
          colorScheme="messenger"
          variant="solid"
          mt={buttonStyle.mt}
          bgColor={buttonStyle.bgColor}
          textColor={buttonStyle.textColor}
          onClick={() => (window.location.href = "/")}
        >
          {language === "en" ? "Back" : "Înapoi"}
        </Button>
      </VStack>
    </Center>
  );
};

export default OopsPage;
