import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ChakraProvider, baseTheme, extendTheme } from "@chakra-ui/react";
import { App, Plan, OopsPage } from "./pages";
import {
  Layout,
  LoadingPage,
  LanguageProvider,
  UserProvider,
} from "./components";

import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/roboto";
import "@fontsource/pt-sans";
import "@fontsource/lexend/400.css";
import "@fontsource/questrial/400.css";

import AuthPage from "./pages/AuthPage";
import ChangePasswordPage from "./components/ChangePasswordPage";

const theme = extendTheme({
  fonts: {
    heading: `'Switzer', sans-serif`,
    body: `'Switzer', sans-serif`,
    serif: `'Libre Baskerville', serif`,
  },
  colors: {
    primary: baseTheme.colors.messenger,
  },
  withDefaultColorScheme: {
    colorScheme: "primary",
  },
});

const Index: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <UserProvider>
        <LanguageProvider>
            <Router>
              <Layout>
                <Routes>
                  <Route path="/" element={<App />} />
                  <Route path="/load" element={<LoadingPage />} />
                  <Route
                    path="/plan"
                    element={<Plan />}
                  />
                  <Route path="/test" element={<OopsPage />} />
                  <Route path="/pricing" element={<OopsPage />} />
                  <Route path="/authorize" element={<AuthPage />} />
                  <Route
                    path="/change-password"
                    element={<ChangePasswordPage />}
                  />
                </Routes>
              </Layout>
            </Router>
        </LanguageProvider>
      </UserProvider>
    </ChakraProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));
